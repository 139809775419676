@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Varela+Round&display=swap');
* {
    font-family: "Public Sans","sans-serif";
}
@tailwind base;
@tailwind components;
@tailwind utilities;


/* For Firefox */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Chrome, Safari, and newer versions of Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For IE11 and Edge */
input[type="number"]::-ms-clear {
    display: none;
}

.topline-animation {
    @apply relative transition-all duration-300 ease-in-out;  /* Color for the text */
}

.topline-animation::before {
    @apply block content-[''] h-0.5 bg-blue-700 absolute left-0 w-0 transition-all duration-300 ease-in-out;  /* bg-red-500 is just an example color for the topline */
    top: -0.2rem;
}

.topline-animation:hover::before {
    @apply w-full;
}



.underline-animation {
    @apply relative transition-all duration-300 ease-in-out;
    color: black;  /* Initial color */
}

.underline-animation::before {
    @apply block content-[''] h-0.5 bg-current absolute left-0 w-0 transition-all duration-300 ease-in-out;
    bottom: -0.2rem;
}

.underline-animation:hover::before {
    @apply w-full;
}
::-webkit-scrollbar-thumb {
    background-color: gray; /* Or any other color you prefer */
    z-index: 1000;
}
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}
#mobile-wrapper {
    width: 375px;
    height: 95vh;
    margin: 15px auto;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    overflow-y: auto;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    .checkbox-wrapper-14 input[type=checkbox] {
        --active: #275EFE;
        --active-inner: #fff;
        --focus: 2px rgba(39, 94, 254, .3);
        --border: #BBC1E1;
        --border-hover: #275EFE;
        --background: #fff;
        --disabled: #F6F8FF;
        --disabled-inner: #E1E6F9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 1px solid var(--bc, var(--border));
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    }
    .checkbox-wrapper-14 input[type=checkbox]:after {
        content: "";
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
    }
    .checkbox-wrapper-14 input[type=checkbox]:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    .checkbox-wrapper-14 input[type=checkbox]:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: 0.9;
    }
    .checkbox-wrapper-14 input[type=checkbox]:disabled:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
    }
    .checkbox-wrapper-14 input[type=checkbox]:disabled + label {
        cursor: not-allowed;
    }
    .checkbox-wrapper-14 input[type=checkbox]:hover:not(:checked):not(:disabled) {
        --bc: var(--border-hover);
    }
    .checkbox-wrapper-14 input[type=checkbox]:focus {
        box-shadow: 0 0 0 var(--focus);
    }
    .checkbox-wrapper-14 input[type=checkbox]:not(.switch) {
        width: 21px;
    }
    .checkbox-wrapper-14 input[type=checkbox]:not(.switch):after {
        opacity: var(--o, 0);
    }
    .checkbox-wrapper-14 input[type=checkbox]:not(.switch):checked {
        --o: 1;
    }
    .checkbox-wrapper-14 input[type=checkbox] + label {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        margin-left: 4px;
    }

    .checkbox-wrapper-14 input[type=checkbox]:not(.switch) {
        border-radius: 7px;
    }
    .checkbox-wrapper-14 input[type=checkbox]:not(.switch):after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
    }
    .checkbox-wrapper-14 input[type=checkbox]:not(.switch):checked {
        --r: 43deg;
    }
    .checkbox-wrapper-14 input[type=checkbox].switch {
        width: 38px;
        border-radius: 11px;
    }
    .checkbox-wrapper-14 input[type=checkbox].switch:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 17px;
        height: 17px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
    }
    .checkbox-wrapper-14 input[type=checkbox].switch:checked {
        --ab: var(--active-inner);
        --x: 17px;
    }
    .checkbox-wrapper-14 input[type=checkbox].switch:disabled:not(:checked):after {
        opacity: 0.6;
    }
}

.checkbox-wrapper-14 * {
    box-sizing: inherit;
}
.checkbox-wrapper-14 *:before,
.checkbox-wrapper-14 *:after {
    box-sizing: inherit;
}



.animate-fade-in {
    animation: fade-in 1s;
}
.animate-fade-out {
    animation: fade-out 1s;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 50;
    }
    100% {
        opacity: 100;
    }
}
@keyframes fade-out {
    0% {
        opacity: 100;
    }
    50% {
        opacity: 50;
    }
    100% {
        opacity: 0;
    }
}


