

/* ... existing styles ... */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modal-input {
    border: 2px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    outline: none;
}

/* ... existing styles ... */

.media-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    background: #eff6ff;
    justify-content: center;
    min-height: 50vh;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.media-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    transition: transform 0.2s ease;
}
.path-header {
    padding: 0.5rem;
    text-align: center;
    color: white;
    font-weight: bold;
    background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
    border-radius: 0.25rem; /* Adjust the border-radius to match your design preference */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for a layered effect */
}

.media-item:hover {
    transform: translateY(-5px);
}

.media-icon {
    font-size: 4rem;
    margin-bottom: 0.5rem;
}

.folder {
    color: #f1c40f; /* folder icon color */
}

.file {
    color: #3498db; /* file icon color */
}

.media-name {
    text-align: center;
    word-break: break-word; /* This ensures the text breaks to prevent overflow */
}
